.button {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  /* Button styling */
  min-width: fit-content;
  height: 42px;
  padding-left: 20px;
  padding-right: 20px;
  background: #0088cc;
  border: none;
  border-radius: 32px;

  /* Centering */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 9999;
}

.text {
  /* Text */
  width: fit-content;
  height: 18px;

  /* Caption bold */
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;

  /* identical to box height, or 129% */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  /* Greys/White/Dark */
  color: #ffffff;

  /* Truncate text when button size is too small */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.blackout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998; /* Lower z-index to appear behind the button */
}
